<template>
  <div>
    <b-row class="no-gutters align-items-center">
      <b-col class="col">
        <b-row class="no-gutters mr-3 align-items-center">
          <b-col class="col-12 col-xl font-weight-bold">
            {{ userFullName }}
          </b-col>
          <b-col
            class="col-12 col-xl text-nowrap text-xl-center text-muted body__2"
          >
            {{ userEmail }}
          </b-col>
          <b-col
            class="col-12 col-xl text-nowrap text-xl-center text-muted body__2"
          >
            {{ showBranchInfo ? userBranchName : "-" }}
          </b-col>
          <b-col class="col-12 col-xl text-nowrap text-xl-center">
            <b-badge pill :variant="userBadge.badgeVariant">{{
              $t(userBadge.text)
            }}</b-badge>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-auto text-right">
        <b-button
          size="sm"
          class="ml-auto mr-2 text-uppercase"
          variant="outline-gray-dark"
          @click="editUser"
        >
          <span>{{ $t("button.edit") }}</span>
        </b-button>
        <b-button
          size="sm ml-auto text-uppercase"
          variant="red"
          :disabled="isDeleteBtnDisabled"
          @click="deleteUser"
        >
          {{ $t("button.delete") }}
        </b-button>
      </b-col>
    </b-row>
    <hr class="my-3" />
  </div>
</template>

<script>
import Utils from "./../../Utils";
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    branch: {
      type: Object,
    },
  },
  computed: {
    userId() {
      return this.user._id;
    },
    userFullName() {
      return this.user.firstName + " " + this.user.lastName;
    },
    userEmail() {
      return this.user.email;
    },
    client() {
      return { ...this.$store.getters["auth/getUserAccount"] };
    },
    loggedInUserSubType() {
      return this.$store.getters["auth/getUserSubType"];
    },
    userBranchName() {
      return this.branch?.name || this.$t("page.users.noBranchAssigned");
    },
    showBranchInfo() {
      if (
        this.loggedInUserSubType === Utils.USER_SUBTYPE.ADMIN ||
        this.client.shareProjects
      ) {
        if (this.user.subType === Utils.USER_SUBTYPE.ADMIN) {
          // Admin users don't belong to a specific branch
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    userBadge() {
      switch (this.user.subType) {
        case "branch":
          return { text: "page.users.branchUser", badgeVariant: "success" };
        case "collaborator":
          return {
            text: "page.users.collaboratorUser",
            badgeVariant: "danger",
          };
        case "researcher":
          return { text: "page.users.researcherUser", badgeVariant: "warning" };
        default:
          return { text: "page.users.adminUser", badgeVariant: "info" };
      }
    },
    isDeleteBtnDisabled() {
      // if user has subType admin he cannot delete himself (make sure at least 1 admin remains)
      if (
        this.$store.getters["auth/getUserId"] === this.user._id &&
        this.user.subType === "admin"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    editUser() {
      this.$emit("editUser", this.user);
    },
    deleteUser() {
      this.$emit("deleteUser", this.user);
    },
  },
};
</script>

<style scoped>
.action__icon {
  width: 14px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
</style>
