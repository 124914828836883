<template>
  <b-modal
    v-model="state"
    size="lg"
    content-class="rounded-0"
    footer-class="border-top-0 justify-content-start"
    centered
    no-close-on-esc
    no-close-on-backdrop
    @cancel="onCancel"
  >
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase font-weight-bold">
        {{ $t(modalTitle) }}
      </h5>
    </template>
    <template v-slot:default>
      <div v-if="modalType === 'DELETE'">
        <h5 class="text-red">
          {{
            $t("page.users.deleteUserMsg", {
              userFullName,
            })
          }}
        </h5>
      </div>
      <div v-else>
        <validation-observer ref="observer">
          <validation-provider
            :name="$t('firstName')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              label-class="font-weight-bold"
              :label="$t('firstName')"
              label-for="firstName"
            >
              <b-form-input
                id="firstName"
                v-model="firstName"
                :state="inputValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('lastName')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              class="mt-4"
              label-class="font-weight-bold"
              :label="$t('lastName')"
              label-for="lastName"
            >
              <b-form-input
                id="lastName"
                v-model="lastName"
                :state="inputValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="Email"
            :rules="{ required: true, email }"
            v-slot="validationContext"
          >
            <b-form-group
              class="mt-4"
              label-class="font-weight-bold"
              :label="$t('email')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                :state="inputValidationState(validationContext)"
                @input="email = email.toLowerCase()"
                :disabled="modalType === 'EDIT'"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('position')"
            :rules="{ required: true }"
            v-slot="validationContext"
            v-if="modalType === 'ADD'"
          >
            <b-form-group
              class="mt-4"
              label-class="font-weight-bold"
              :label="$t('position')"
              label-for="position"
            >
              <b-form-input
                id="position"
                v-model="position"
                :state="inputValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('phone')"
            :rules="{ required: true }"
            v-slot="validationContext"
            v-if="modalType === 'ADD'"
          >
            <b-form-group
              class="mt-4"
              label-class="font-weight-bold"
              :label="$t('phone')"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="phone"
                :state="inputValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('subType')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              class="mt-4"
              label-class="font-weight-bold"
              :label="$t('subType')"
              label-for="subType"
            >
              <b-form-select
                id="subType"
                :state="inputValidationState(validationContext)"
                v-model="subType"
                :options="subTypeListAllowedByUserSubType"
                value-field="value"
                text-field="name"
                @change="handleUserSubTypeChange"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>
                    {{ $t("selectAnOption") }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            :name="$t('branch')"
            :rules="{ required: true }"
            v-slot="validationContext"
            v-if="showBranchDropdown"
          >
            <b-form-group
              class="mt-4"
              label-class="font-weight-bold"
              :label="$t('branch')"
              label-for="branch"
            >
              <b-form-select
                id="branch"
                :state="inputValidationState(validationContext)"
                v-model="branch"
                :options="branches"
                value-field="_id"
                text-field="name"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>
                    {{ $t("selectAnOption") }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </div>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <b-row class="no-gutters justify-content-start w-100">
        <b-col class="ml-auto col-auto mr-1 mb-1">
          <b-button variant="outline-gray-dark" @click="cancel">{{
            $t("button.cancel")
          }}</b-button>
        </b-col>
        <b-col class="col-auto mr-1 mb-1">
          <b-button @click="onProceed" :disabled="!formValid">
            {{ $t(actionBtn) }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import Utils from "./../../Utils";
export default {
  props: {
    modalState: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: "ADD",
    },
    branches: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  watch: {
    modalState: {
      handler: function () {
        if (
          this.modalState &&
          (this.modalType === "EDIT" || this.modalType === "DELETE")
        ) {
          this.activeUser = { ...this.user };
        }
        // Set empty branchId for admin users in order for v-validate to work if user is changed from admin to branch etc
        if (this.activeUser.subType === "admin") {
          this.$set(this.activeUser, "branchId", "");
        }
        // For non-admin users logged in set their own branchId as the new user branchId(can only create users for their own branch)
        if (this.loggedInUserSubType !== "admin")
          this.activeUser.branchId = this.userBranchId;
      },
      immediate: false,
    },
    activeUser: {
      // Workaround/Hack in order to retrieve validation-observer status by watching activeUser object changes
      // https://github.com/logaretm/vee-validate/issues/2609
      handler: function () {
        if (this.modalType === "DELETE") {
          this.formValid = true;
        } else if (this.$refs.observer) {
          this.$refs.observer
            .validate()
            .then((status) => (this.formValid = status));
        }
      },
      deep: true,
      immediate: false,
    },
  },
  data() {
    return {
      formValid: false,
      activeUser: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        position: "",
        subType: "",
        branchId: "",
        type: "client",
      },
    };
  },
  methods: {
    resetForm() {
      this.formValid = false;
      this.activeUser = {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        position: "",
        subType: "",
        branchId: "",
        type: "client",
      };
    },
    onCancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    onProceed() {
      if (this.modalType === "DELETE") {
        this.$emit("deleteUser", this.activeUser);
      } else if (this.modalType === "EDIT") {
        this.$emit("updateUser", this.activeUser);
      } else {
        this.$emit("createUser", this.activeUser);
      }
      this.resetForm();
    },
    inputValidationState({ dirty, validated, valid = null, untouched }) {
      if (!untouched && (dirty || validated)) return valid ? null : valid;
      else return null;
    },
    handleUserSubTypeChange() {
      if (this.activeUser.subType === "admin") {
        // Remove branchId if was previously assigned to one
        this.activeUser.branchId = "";
      }
      // Retrieve form validation status
      this.$nextTick(() => {
        this.$refs.observer.validate().then((status) => {
          this.formValid = status;
        });
      });
    },
  },
  computed: {
    modalTitle() {
      switch (this.modalType) {
        case "EDIT":
          return "page.users.editUserTitle";
        case "DELETE":
          return "page.users.deleteUserTitle";
        default:
          return "page.users.createUserTitle";
      }
    },
    userFullName() {
      return this.activeUser.firstName + " " + this.activeUser.lastName;
    },
    loggedInUserSubType() {
      return this.$store.getters["auth/getUserSubType"];
    },
    userBranchId() {
      return this.$store.getters["auth/getUserBranchId"];
    },
    actionBtn() {
      switch (this.modalType) {
        case "EDIT":
          return "button.save";
        case "DELETE":
          return "button.confirm";
        default:
          return "button.createAccount";
      }
    },
    subTypeListAllowedByUserSubType() {
      const userSubTypesList = Utils.USER_SUBTYPE_LIST;
      // filter allowed user types based on currently logged in user subType
      const subTypeListAllowed = userSubTypesList.filter((type) => {
        if (this.loggedInUserSubType === "branch") {
          // branch -> can create only collaborators and researchers
          return type.value === "collaborator" || type.value === "researcher";
        } else if (this.loggedInUserSubType === "collaborator") {
          // collaborator -> can create only researchers
          return type.value === "researcher";
        } else {
          // admin -> can create all (admin, branch, collaborator, researcher)
          return true;
        }
      });
      return subTypeListAllowed;
    },
    showBranchDropdown() {
      return this.activeUser.subType && this.activeUser.subType !== "admin";
    },
    state: {
      get() {
        return this.modalState;
      },
      set(state) {
        this.$emit("updateUserModalState", state);
      },
    },
    firstName: {
      get() {
        return this.activeUser.firstName;
      },
      set(firstName) {
        this.activeUser.firstName = firstName;
      },
    },
    lastName: {
      get() {
        return this.activeUser.lastName;
      },
      set(lastName) {
        this.activeUser.lastName = lastName;
      },
    },
    email: {
      get() {
        return this.activeUser.email;
      },
      set(email) {
        this.activeUser.email = email;
      },
    },
    phone: {
      get() {
        return this.activeUser.phone;
      },
      set(phone) {
        this.activeUser.phone = phone;
      },
    },
    position: {
      get() {
        return this.activeUser.position;
      },
      set(position) {
        this.activeUser.position = position;
      },
    },
    subType: {
      get() {
        return this.activeUser.subType;
      },
      set(subType) {
        this.activeUser.subType = subType;
      },
    },
    branch: {
      get() {
        return this.activeUser.branchId;
      },
      set(branchId) {
        this.activeUser.branchId = branchId;
      },
    },
  },
};
</script>

<style></style>
