<template>
  <b-col class="col-12 col-md-8 pt-5 px-3 p_b__3">
    <h4 class="mb-4 font-weight-normal text-muted">
      {{ $t("page.users.title") }}
    </h4>
    <div>
      <b-card no-body class="px-3 py-3 mb-3">
        <div class="d-flex align-items-center">
          <h6 class="mb-0 font-weight-normal text-uppercase">
            {{ $t("sideMenu.users") }}
          </h6>
          <b-button
            size="sm ml-auto text-uppercase"
            @click="openAddUserModal"
            v-if="!isBranchListEmpty"
          >
            {{ $t("button.add") }}
          </b-button>
        </div>
        <hr class="my-3 mx-n3 divider" />
        <div v-for="user in userList" :key="user.id">
          <user-list-item
            :user="user"
            :branch="userBranch(user.branchId)"
            @editUser="openEditUserModal"
            @deleteUser="openDeleteUserModal"
          />
        </div>
        <div v-if="isBranchListEmpty">
          <div class="text-center font-weight-bold">
            {{ $t("page.branches.emptyList") }}
          </div>
        </div>
        <div v-else-if="isEmptyUserList">
          <div class="text-center font-weight-bold">
            {{ $t("page.users.emptyList") }}
          </div>
        </div>
      </b-card>
    </div>
    <user-modal
      :modalState="modalState"
      :modalType="modalType"
      :branches="branchList"
      :user="user"
      @createUser="createUser"
      @updateUser="updateUser"
      @deleteUser="deleteUser"
      @updateUserModalState="updateUserModalState"
    />
  </b-col>
</template>

<script>
import UserListItem from "../components/Users/UserListItem.vue";
import UserModal from "../components/Users/UserModal";
export default {
  components: {
    UserListItem,
    UserModal,
  },
  watch: {},
  data() {
    return {
      modalState: false,
      modalType: "ADD",
      user: {},
    };
  },
  created() {},
  computed: {
    branchList() {
      return this.$store.getters["multiuser/getBranches"];
    },
    userList() {
      const users = [...this.$store.getters["multiuser/getUsers"]];
      const sortedUsersBySubTypeDesc = users.sort((a, b) => {
        const fa = a.subType.toLowerCase();
        const fb = b.subType.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      return sortedUsersBySubTypeDesc;
    },
    isEmptyUserList() {
      return this.userList.length === 0;
    },
    isBranchListEmpty() {
      return this.branchList.length === 0;
    },
  },
  methods: {
    userBranch(branchId) {
      return this.branchList.find((branch) => {
        return branch._id === branchId;
      });
    },
    createUser(user) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("multiuser/createClientUser", user)
        .then(this.updateUserModalState(false))
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
    updateUser(user) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("multiuser/updateClientUser", user)
        .then(this.updateUserModalState(false))
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
    deleteUser(user) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("multiuser/deleteClientUser", user._id)
        .then(this.updateUserModalState(false))
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    },
    updateUserModalState(state) {
      this.modalState = state;
    },
    openAddUserModal() {
      this.user = {};
      this.modalType = "ADD";
      this.updateUserModalState(true);
    },
    openEditUserModal(user) {
      this.user = user;
      this.modalType = "EDIT";
      this.updateUserModalState(true);
    },
    openDeleteUserModal(user) {
      this.user = user;
      this.modalType = "DELETE";
      this.updateUserModalState(true);
    },
  },
};
</script>

<style scoped>
.divider {
  border-top: 1px black solid;
}
.action__icon {
  width: 14px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
</style>
